.blog-home-main{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-home-outer {
    display: flex;
    justify-content: center;
    //flex-wrap: wrap;
    //justify-content: space-evenly;
    align-items: center;
    //background-color: aqua;
    min-height: 500px;
    width: 80%;
    background-color: var(--color-main-color-ivory);
}