.blog-postImg{
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.blog-postInfo{
    text-align: center;
    margin: 20px 10px;
    font-family: 'Lora', serif;
    font-size: 28px;
}

.blog-postTitle {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.blog-postDate {
    font-family: 'Lora', serif;
    font-style: italic;
    font-size: 13px;
    color: #999;
    margin-top: 15px;
}

.blog-postDescription {
    color: #666;
    font-size: 18px;
    line-height: 25px;
    overflow-wrap: break-word;
    hyphens: manual;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.dynamicPostHomeRedirectStyle {
    text-decoration: none;
    margin-top: 15px;
    cursor: pointer;
}

.dynamicPostHomeRedirectStyle:link {
    color: black;
}

.dynamicPostHomeRedirectStyle:visited {
    color: black;
}