.product-header-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(252, 252, 252);
    width: 100%;
}

.product-head-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(251, 255, 255);
    width: 100%;
}


.product-header-leftside {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    padding-left: 10px;
}

.product-header-leftside>h3{
    display: flex;
    text-align: center;
    padding-right: 10px;
}

.product-header-leftside>details{
    display: flex;
    text-align: center;
    position: relative;
    padding-right: 15px;
    cursor: pointer;
}


.product-header-rightside {
    display: flex;
    align-items: center;
    align-content: flex-end;  
    padding-right: 10px;
}

.product-header-list{
    background-color: #f1f1f1;
    min-width: 90px;
    position: absolute;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 8;
    list-style: none;
}

.product-header-details::-webkit-details-marker{
    display: none;
  }
  
