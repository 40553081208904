.input-fields-list-write-page {
    font-size: 15px;
    width: 30%;
    margin-bottom: 5px;
}

.write-page-input-ingredients-container {
    position: relative;
    margin-bottom: 10px;
}

.write-page-input-ingredients-container div {
    display: flex;
    margin-bottom: 10px;
    //flex-direction: column;
    //flex-wrap: wrap;
}

.write-page-input-ingredients-remove {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55px;
}

.write-page-input-ingredients-add {
    width: 100px;
}



@media screen and (min-width: 650px) {
    .write-page-input-ingredients-container div {
        flex-wrap: wrap;
    }

    .input-fields-list-write-page {
        margin-right: 10px;
    }
}

@media screen and (max-width: 650px) {
    .input-fields-list-write-page {
        width: 100%;
    }

    .ingredients-container-outer {
        width: 300px;
    }

    .write-page-input-ingredients-remove {
        margin-top: 10px;
    }

    .write-page-input-ingredients-container div {
        flex-direction: column;
    }
    
}