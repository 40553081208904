.BlogHeaderCarousel {
    display: none;
    position: relative;
    height: 300px;
    overflow: hidden;
}

.header-carousel-slide {
    display: flex;
    position: absolute;
    width: 100%;
    height: 300px;
    transition: transform 0.2s ease;
}

.slide-hidden {
    //display: none;
    //visibility: hidden;
}



@media screen and (max-width: 737px) { 
    .BlogHeaderCarousel {
        display: flex;
        //flex-direction: column;
        width: 100%;
        position: relative;
    }

    .header-carousel-slide {
        //position: relative;
        //max-height: 400px;
        overflow: hidden;
        //transition: transform 0.5s ease;
    }

    .Blog-Header-Image-Container {
        display: flex;
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;
    }


    .Blog-header-background-Mobile {
        //position: absolute;
        //top: -230px;
        width: 100%;
        object-fit: cover;
    }

    .Blog-header-Mobile-Text-Container {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 100%;
        width: 100%;
        //justify-content: center;
        align-items: center;
    }

    .Blog-header-Mobile-Text-Inner-Back {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100px;
        top: 10%;
        //justify-content: center;
        //align-items: center;
        align-items: flex-end;
        background-color: rgba(81, 87, 85, 0.507);
        transition: height 0.5s ease;
    }

    .Blog-header-Mobile-Text-Inner-Back-None {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        width: 100%;
        height: 100px;
        top: 30%;
    }

    .Blog-header-Mobile-Text-Inner {
        position: absolute;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        height: 100px;
        padding-right: 5px;
        padding-top: 5px;
        //background-color: aqua;
        //justify-content: center;
    }

    .Blog-header-Mobile-Text-1 {
        font-family: 'Helvetica', sans-serif;
        color: #FFFFF0;
        letter-spacing: 0.8px;
        transition: font-size 0.6s ease, padding-top 0.6s ease;
    }

    .Blog-header-Mobile-Text-2 {
        text-align: end;
        font-family: 'Helvetica', sans-serif;
        transition: font-size 0.6s ease;
        color: #FFFFF0;
    }

    //Navigation
    .header-indicator-image {
        //background-color: #dd00ff;
        background-color: rgb(0, 0, 0);
        border: 1px solid white;
        height: 0.9rem;
        width: 0.9rem;
        border-radius: 100%;
        //border: none;
        outline: none;
        box-shadow: 0px 0px 5px #555;
        margin: 0 0.35rem;
        cursor: pointer;
    }
    
    .header-indicator-inactive {
        background-color: #f1f1f1;
        height: 0.5rem;
        width: 0.5rem;
    }


    .Blog-header-navigation-image {
        display: flex;
        position: absolute;
        bottom: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    // Styling specific to the image shown
    .Blog-Header-Text-1-1 {
        font-size: 22px;
    }

    .Blog-Header-Text-1-2 {
        font-size: 35px;
    }

    .Blog-Header-Text-2-1 {
        font-size: 25px;
    }

    .Blog-Header-Text-2-2 {
        font-size: 25px;
        
    }

    .Blog-Header-Text-2-3 {
        font-size: 25px;
        
    }

    .Blog-Header-Text-3-1 {
        font-size: 25px;
    }

    .Blog-Header-Text-3-2 {
        font-size: 25px;
        
    }

    .Blog-Header-Text-4-1 {
        padding-top: 45px;
        font-size: 40px;
    }

    .Blog-Header-Text-4-2 {
        
    }

    .Blog-Header-Text-5-1 {
        font-size: 22px;
    }

    .Blog-Header-Text-5-2 {
        font-size: 35px;
    }

}

@media screen and (max-width: 620px) {
    .Blog-header-Mobile-Text-Inner-Back {
        height: 75px;
    }

    .Blog-header-Mobile-Text-Inner {
        height: 80px;
    }


    .Blog-header-background-Mobile {
        //object-fit: fill;
    }

    .Blog-Header-Text-1-1 {
        font-size: 16px;
    }

    .Blog-Header-Text-1-2 {
        font-size: 22px;
    }

    .Blog-Header-Text-2-1 {
        font-size: 18px;
    }

    .Blog-Header-Text-2-2 {
        font-size: 18px;
    }

    .Blog-Header-Text-2-3 {
        font-size: 18px;
    }

    .Blog-Header-Text-3-1 {
        font-size: 18px;
    }

    .Blog-Header-Text-3-2 {
        font-size: 18px;
        
    }

    .Blog-Header-Text-4-1 {
        padding-top: 28px;
        font-size: 32px;
        
    }

    .Blog-Header-Text-4-2 {
        
    }

    .Blog-Header-Text-5-1 {
        font-size: 14px;
    }

    .Blog-Header-Text-5-2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 496px) {
    .Blog-header-background-Mobile {
        //object-fit: contain;
    }
}