@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

@font-face {
  font-family: segoescript;
  src: url(../public/CustomFonts/segoescript.ttf);
}

:root {
  --color-red-light: rgb(231, 77, 60);
  /* #E74D3C */
  --color-red-dark: rgb(192, 57, 43);
  /* #C0392B */
  --color-orange-light: rgb(230, 125, 34);
  /* #E67D22 */
  --color-orange-dark: rgb(211, 84, 0);
  /* #D35400 */
  --color-yellow-light: rgb(241, 196, 15);
  /* #F1C40F */
  --color-yellow-dark: rgb(243, 156, 18);
  /* #F39C12 */
  --color-green-light: rgb(46, 204, 112);
  /* #2ECC70 */
  --color-green-dark: rgb(39, 174, 96);
  /* #27AE60 */
  --color-turquoise-light: rgb(26, 188, 156);
  /* #1ABC9C */
  --color-turquoise-dark: rgb(22, 160, 132);
  /* #16A084 */
  --color-blue-light: rgb(52, 152, 219);
  /* #3498DB */
  --color-blue-dark: rgb(41, 128, 185);
  /* #2980B9 */
  --color-purple-light: rgb(156, 89, 182);
  /* #9C59B6 */
  --color-purple-dark: rgb(142, 68, 173);
  /* #8E44AD */
  --color-white-light: rgb(236, 240, 241);
  /* #ECF0F1 */
  --color-white-dark: rgb(189, 195, 199);
  /* #BDC3C7 */
  --color-gray-light: rgb(149, 165, 166);
  /* #95A5A6 */
  --color-gray-dark: rgb(127, 140, 141);
  /* #7D8C8D */
  --color-black-light: rgb(52, 73, 94);
  /* #34495E */
  --color-black-dark: rgb(44, 62, 80);
  /* #2c3e50 */
  --color-black-dark2: rgb(27, 23, 23);
  --color-black-light2: rgb(34, 32, 32);
  /* 2d3032 */
  --color-black-dark-light: rgb(45,48,50);

  /* New Colors Added */
  --color-dark-grayV1: rgb(86, 88, 86);
  --color-light-grayV1: rgb(121, 121, 121);
  --color-light-green: #7cac6b;

  /*Moms Colours*/
  /*Text Colours*/
  --color-main-text: rgb(37,38,11);
  --color-main-color-ivory: rgb(255, 255, 240);
  --color-main-color-bone-white: rgb(249, 246, 238);
  --color-top-bar-hover: rgb(147,43,67);
  --color-main-background: rgb(140,42,60);
  --color-main-background-secondary: rgb(114,32,32);

}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body { 
  background-color: #FFFFF0;
}




/*Home page*/

.main-box-component-outer-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-white-light);
}


.main-box-component-outer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 15px;
  box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
  max-width: 1400px;
  width: 85vw;
  z-index: 2;
  padding: 2em;
}

.main-box-component-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  margin: 25px;
  color: white  
}

.main-box-component-img {
  margin: 20px;
  padding: 10px;
  background-color: #1e1b1b;
  width: 300px;
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border: rgb(153, 8, 8);
  border-style: solid;
  border-width: 1px;
  border-radius: 2%;
}

/*Login Page*/
/* Center the outer container */


/* Style for the outer box */
#login-outer-field-box {
  flex-direction: column;
  display: flex;
  background-color: var(--color-black-dark2);
  /*padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #e3e3e3;*/
}

.login-outer-field-box-div{
  width: 60vw;
  z-index: 2;
  padding: 2em;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(203, 212, 209);
  border-radius: 25px;
}



/* Style for the inner box */
.login-inner-field-box {
  display: flex;
  flex-direction: column; /* Adjust the flex direction as needed */
  align-items: center;
  justify-content: center;
  border: 1px solid #af2e2e;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  padding: 5px;
}

/* Style for the email input wrapper */
.login-inner-field-box .email-input-wrapper {
  width: 100%;
  background-color: #c5c2c2;
}

/* Style for the header of the email input wrapper */
.email-input-wrapper-header {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  font-size: 15px;
}

/* Style for the login field */
.login-field {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.sign-in-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.sing-in-outter-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.login-button-main {
    border: 0;
    border-radius: 25px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    letter-spacing: normal;
    line-height: 1.17;
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background-color: rgba(147, 250, 165);
}


.login-button-main:hover {
  color: rgb(0, 0, 0);
  background: rgba(147, 250, 165, 0.5);
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  cursor: pointer;
}

.sign-up-button-inner {
  padding-left: 4px;
  padding-right: 4px;
  color: rgb(15, 175, 23);
}


.or-divider-line {
  width: 100%;
  margin: 10px 0;
  text-align: center;
  font-size: 28px;
  position: relative;
  background-color: #243fac;
}

.or-divider-line::after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #d41919;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

.or-divider-line>span {
  background-color: #57585C; /* Same as the parents Background */
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 10px 0 10px;
  color: white;
  position: relative;
  font-family: calibri;
  font-weight: lighter;
  margin: 0;
}

/* other methods of login buttons */
.button-wrapper-standard {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 48px;
  color: #282a35;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  margin: 4px;
}


#login-image-size{
  height: 15px;
  width: 15px;
  object-fit: contain;
  vertical-align: middle;
}

#login-text-size {
  font-size: 14px;
  letter-spacing: 1px;
}


#button-wrapper-colours-fb {
  background: #1877f2!important;
  border-color: #1877f2!important;
  color:aliceblue
}


.product-container-outer-box {
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  width: 80%;
  max-width: 1500px;
  min-height: 500px;
}
.product-container-centering-box{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.product-container-box{
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 10px 0px;
}

/* SVG colours*/
