.breadcrumbNavContainer {
  box-sizing: border-box;
  word-wrap: break-word;
  //width: calc(100% - 425px);
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 15px;
  //padding-left: 20px;
  
}

.breadcrumbNav {
  position: relative;
  text-decoration: none;
  font-family: 'Helvetica', sans-serif;
  letter-spacing: 0.8px;
  //font-size: 13px;
  color: #555;
}

.breadcrumbNav:visited{
  color: #555;
}

.breadcrumb-arrow {
  //font-size: 13px;
  margin-left: 7px;
  margin-right: 7px;
}


@media screen and (max-width: 775px) {
  .breadcrumbNavContainer {
    font-size: 10px;
  }
}
