.Edit-Page-Posts-Outer {
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    font-family: "Verdana", sans-serif;
    width: 100%;
}

.Edit-Page-Main-Text {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
    width: 100%;
    max-width: 1000px;
}

.Edit-Page-Title {
    font-size: 30px;
    border: none;
    width: 80%;
    background-color: rgb(253, 148, 121);
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    margin: 10px 0 10px 0;
    //margin-bottom: 10px;
}

.Edit-Page-Description {
    min-height: 60vh;
    max-width: 1000px;
    width: 80%;
    padding-top: 10px;
    background-color: rgb(253, 148, 121);
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    border: none;
    resize: none;
}

.Edit-Page-Defaults::placeholder {
    color: black;
    font-weight: 400;
}

.Edit-Page-Defaults:focus {
    outline-style: none;
}

.Post-Selection {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
}

.Current-Post-ID-Edit {
    font-size: 14px;
    width: 80%;
}

// Ingredients

.Edit-Page-Ingredients-Text {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
    width: 100%;
    max-width: 1000px;
}
 
// Edit Images
.Edit-Page-File-Outside {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 10px 0;
}

.Edit-Page-File-Input-Background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 810px;
}

.Edit-Page-Header-Image {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
}

.Edit-Page-HImage-Closed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grayV1);
    padding: 10px;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    cursor: pointer;
}

.Edit-Page-HImage-Closed:hover {
    background-color: rgb(103, 104, 105);
}

.Edit-Page-HImage-Open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grayV1);
    border-radius: 15px;
    height: 300px;
    width: 300px;
}

.Edit-Page-Remove-Symbol {
    position: absolute;
    left: 20px;
    bottom: 20px;
    border-radius: 50%;
}

.Edit-Page-Close-Symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.Edit-Page-Symbol-R {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    font-size: 20px;
    color: rgba(187, 49, 49, 0.9);
    cursor: pointer;
}

.Edit-Page-Symbol-R:hover {
    color: rgb(231, 27, 27);
}

.Edit-Page-Symbol-C {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: rgb(68, 76, 83);
    background-color: rgb(155, 163, 163);
    cursor: pointer;
}

.Edit-Page-Symbol-C:hover{
    color: rgb(96, 103, 110);
    background-color: rgb(222, 233, 233);
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 650px) {

    .Edit-Page-File-Input-Background {
        height: 700px
    }
    
}
