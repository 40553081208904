.Shop-Page-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 85vh;

    .Shop-Page-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        background-color: aqua;
    }

    .Shop-Page-Inner-Container {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: auto 1fr;
        gap: 10px; /* Optional, adjust spacing between items */
        height: 100%;
        width: 80%;
        max-width: 1440px;
        background-color: aquamarine;

        .Shop-Page-Search {
            grid-column: 1 / 3;
            grid-row: 1 / 2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background-color: aliceblue;
        }

        .Shop-Page-Side-Bar {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            background-color: aliceblue;
        }

        .Shop-Page-Products {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            padding: 10px;
            background-color: aqua;
            display: flex;
            justify-content: center;
            align-items: center;

            .Shop-Page-Items-List {
                list-style: none;
                width: 100%;

                .Shop-Page-Items {
                    width: 33.33%;
                    min-height: 500px;
                    display: inline-block;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-bottom: 18px;
                }
            }
        }
    }

    .Shop-Page-Display {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        min-height: 500px;
        padding: 10px;
        background-color: rgb(145, 101, 44);

        .Shop-Page-Images {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            height: 100%;

            .Shop-Page-Image-Style {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .Shop-Page-Items-Text {
        width: 100%;
        height: 250px;
        font-family: Helvetica, Arial, sans-serif;

        .Shop-page-Items-Title {
            display: flex;
            text-wrap: wrap;
            text-align: center;
            text-transform: uppercase;
            max-width: 314px;
            margin: auto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 1.14286;
        }

        .Shop-page-Items-Price {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px auto 6px;
        }

        .Shop-page-Items-Description {
            text-align: center;
            font-size: 16px;
            overflow-wrap: break-word;
            hyphens: manual;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 9;
            -webkit-box-orient: vertical;
        }
    }

    .Shop-Page-Icons {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;

        .Shop-Page-Heart-Empty {
            position: absolute;
            left: 10px;
            font-size: 20px;
            color: aliceblue;
        }

        

        .Shop-Page-Add-Cart {
            position: absolute;
            right: 10px;
            font-size: 20px;
            color: rgb(255, 255, 255);
        }

        .Shop-Page-Add-Cart:hover {
            cursor: pointer;
            color: aquamarine;
        }
    }

    .Shop-Page-Stars {
            display: flex;
            height: 10px;
            width: 100%;
            margin-bottom: 10px;
    }
}

@media screen and (max-width: 775px) {
    .Shop-Page-Items-List {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style: none;
    }

    .Shop-Page-Items {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: antiquewhite;
        margin: 10px;
        height: 200px;
        width: 200px;
    }
}
