/* SearchBar.css */

.search-container {
    display: flex;
    align-items: center;
    text-align: center;
    background-color: rgb(239, 243, 243);
    height: 100%;
    width: 100%;
  }
  
.search-container-inside-input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
  margin-left: 5px;
}

.search-container-inside-input:focus {
  outline: none;
}

.search-container-inside-input:hover {
  cursor: text;
}

/* Dropdown Content (Hidden by Default) */
.drop-down-cont {
    background-color: #f1f1f1;
    min-width: 90px;
    position: absolute;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 8;
  }