.blog-side-bar{
    flex: 3;
    height: fit-content;
    margin: 20px;
    padding-bottom: 30px;
    background-color: #fdfbfb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 365px;
}

.blog-side-bar-Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.blog-side-bar-Title {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: solid 1px #a7a4a4;
    border-bottom: solid 1px #a7a4a4;
    font-family: "Varela Round", sans-serif;
    font-size: 12px;
    line-height: 19px;
    color: #222222;
    font-weight: 600;
    text-align: center;
}

.blog-side-bar-Item > p {
    padding: 30px;
  }
  
.blog-side-bar-List {
    list-style-type: none;
    padding-left: 18%;
    margin-bottom: 30px;
}

.blog-side-bar-ListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.blog-side-bar-Social {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-side-bar-icons {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
}

.blog-side-bar-img{
    margin-top: 15px;
    width: 200px;
    height: 250px;
}