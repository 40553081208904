.Star-Rating {
    display: flex;
    align-items: center;
}

.Star-Rating-Icons {
    position: relative;
    color: #ffd700;
    margin-right: 1px;
    font-size: 10px;

    &:last-of-type {
        margin-right: 0;
      }
    
}


.Half-Star-Container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
}

.Half-Star-Filled {
    position: absolute;
    top: 3px;
    //left: 16px;
    width: 10px;
    height: 10px;
    clip-path: polygon(50% 0%, 67% 28%, 98% 35%, 76% 59%, 79% 91%, 50% 74%, 21% 91%, 25% 57%, 2% 35%, 34% 28%);
    //clip-path: polygon(50% 0%, 67% 28%, 98% 35%, 75% 59%, 79% 91%, 50% 70%, 21% 91%, 27% 58%, 2% 35%, 34% 28%);
    //clip-path: polygon(50% 0%, 66% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    z-index: 1;
}

.Half-Star-Border {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffd700;
    clip-path: polygon(50% 0, 79% 90%, 2% 35%, 98% 35%, 21% 90%);
}

