.search-bar-container-outer {
    right: 0;
    /*top: 0;*/
    padding: 1.2rem;
    background-color: white;
    width: 30rem;
    max-width: calc(100vw - 3rem);
    margin-left: auto;
    height: 100%;
    overflow-y: auto;
    pointer-events: all;
    transition: transform .3s ease;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 0 0 0 1px;
    position: fixed;
    z-index: 7;
}

.search-bar-container-invisible-opacity{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with opacity 0.5 */
    z-index: 6; /* Set the z-index below the search bar container */
    /*display: none;*/
}

/* SearchSideBar.css */
.search-bar-div-top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
  height: 2rem;
  width: 100%;
  box-shadow: 0px 0px 8px #ddd;
  margin-bottom: 1rem;
  border-bottom: 2px solid;
  border-color: black;
}



.search-sidebar {
    /* Default styles for hidden sidebar */
    display: none;
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease;
  }
  
  .search-sidebar.visible {
    /* Styles for visible sidebar */
    display: flex;
    width: 300px;
  }

  /*Search Top Div*/
  .search-bar-container-top{
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
  }


  /*Close icon style*/

  .close-side-bar-button-outer{
    display: flex;
    margin-left: auto;
    width: .5rem;
    height: .5rem;
    align-items: center;
    background: transparent;
    border: transparent;
  }

  .close-side-bar-button-outer:hover {
    cursor: pointer;
  }