/*--------------------------*/
/*Footer*/
.blogFooter{
    padding: 30px 0; 
    display: flex;
    justify-content: space-evenly;
    background-color: var(--color-main-background-secondary);
    max-height: 200px;
    width: 100%;
    margin-top: auto;
    font-family: 'Helvetica', sans-serif;
    letter-spacing: 0.7px;
}

.blogFooter .col{
    text-align: center;
    //display: block;
    display: flex;
    flex-direction: column;
    width: 240px;
    color: #FFFFF0;
}

.col {
    display: flex;
    justify-content: center;
}

.blogFooter p{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 4px;
}

.blogFooter ul{
    padding-left: 0;
    list-style-type: none;
}

.blogFooter .col:nth-child(2) ul li {
    display: inline;
}

.blogFooter ul img{
    margin-left: 4px;
    height: 20px;
    display: inline;
    vertical-align: middle;
}


.blogFooter a{
    color: var(--color-white-light);
    text-decoration: none !important;  
}

.blogFooter .col:first-child li:hover{
    opacity: 0.5;
    cursor: pointer;
}

.footer-right-link-icons {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
    background-size: 10px;
}

.footer-social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright-center-position {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.copyright-center-position p {
    color: #FFFFF0;
    font-size: 14px;
    font-weight: 400;
    padding-right: 5px;
}

@media screen and (max-width: 768px) {
    .copyright-center-position p {
        font-size: 8px;
    }

    .footer-right-link-icons {
        height: 14px;
        width: 14px;
        margin-right: 10px;
        cursor: pointer;
        background-size: 10px;
    }

    .col {
        display: flex;
        justify-content: center;
    }

    .col p {
        font-size: 15px;
    }

    .blogFooter a {
        font-size: 14px;
    }

    .blogFooter .col ul li {
        padding-bottom: 4px;
        font-size: 10px;
    }
   
}