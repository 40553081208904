.dynamicPageTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFFFF0;
    color: #555;
    padding: 10px 10px 10px 15px;
    font-family: "Verdana", sans-serif;
}

.dynamicPageTitle header {
    font-size: 33px;
    line-height: 43px;
    letter-spacing: .0025em;
}

.divider-dynamic-page {
    box-sizing: border-box;
    word-wrap: break-word;
    margin: 30px 15px 30px 15px;
    width: calc(100% - 30px);
    border: 1px solid rgba(0,0,0,.12);
}

/* Poster Information formating*/


.dynamicPageProfileInfo {
    display: flex;
    padding: 10px 20px 0 0;
    height: 150px;
    width: 100%;
    justify-content: flex-end;
}

.dynamicPageIconFiller {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px #7a7a7a;
    min-width: 62px;
    min-height: 62px;
    width: 62px;
    height: 62px;
    border-radius: 31px;
    margin-right: 40px;
    overflow: hidden;
}    

.dynamicPageIconFillerInner {
    color: #555;
    font-size: 30px;
}

.dynamicPageProfileImage {
    box-shadow: 0 0 0 1px #7a7a7a;
    min-width: 62px;
    min-height: 62px;
    width: 62px;
    height: 62px;
    border-radius: 31px;
    margin-right: 40px;
    overflow: hidden;
}

.dynamicPageProfileImage img{
    width: 62px;
    height: 62px;
    object-fit: cover;
}

.dynamicPageProfileDetails {
    display: flex;
    flex-direction: column;
    color: #555;
    min-width: 190px;
    font-size: 13px;
}

.dynamicPageProfileDetails div {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.dynamicPageProfileDetails div span {
    padding-left: 4px;
}



/* Product Showcase Section*/
.productInformationInner {
    display: flex;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Verdana", sans-serif;
    width: 100%;
}

.image-description-container {
    display: flex;
}



.productDescriptionSection {
    font-size: 14px;
    line-height: 17px;
    color: #555;
    letter-spacing: .0025em;
    margin-left: 25px;
    margin-right: 100px;
    margin-top: 25px;
    margin-bottom: 15px;
    min-width: 300px
}

.productDescriptionShort {
    display: flex;
    max-width: 400px;
}


.productDescriptionShort ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.productDescriptionShort ul li {
    display: flex;
    padding-bottom: 30px;
}

.productDescriptionShort ul li i {
    font-size: 30px;
    padding-right: 20px;
}

.productDescriptionLong {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: "Verdana", sans-serif;
}

.productDescriptionLong h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.productDescriptionLongPar {
    white-space: pre-line;
    color: #666;
    display: flex;
    flex-direction: column;
    hyphens: manual;
    font-size: 14px;
    letter-spacing: .0025em;
    line-height: 22px;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}



/* Drop dowm Menu's*/

.dropDownMenuContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 10px;
    padding-bottom: 80px;
    width: 100%;
    background-color: #FFFFF0;
}



.dropDownMenuTechnologies {
    box-sizing: border-box;
    word-wrap: break-word;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    //width: calc(100% - 30px);
    width: 71%;
    display: flex;
    border-bottom: 1px solid #e0e0e0;
}

.dropDownMenuTechnologies li {
    flex: 1;
    background-color: var(--color-main-color-bone-white);
    border: 1px solid #ffffff;
    height: 50px;
    line-height: 20px;
    text-align: center;
    margin: 0 -1px -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    list-style: none;
    font-size: 15px;
    letter-spacing: 1px;
    font-family: "Helvetica-Bold", sans-serif;
    color: #555;
    z-index: 2;
    //font-family: 'Montserrat', sans-serif;
}

.dropDownMenuTechnologies li.active {
    z-index: 1;
    color: var(--color-main-background);
    background-color: var(--color-main-color-ivory);
    border-top: 2px solid var(--color-main-background);
    border-left: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
    font-family: "Helvetica-Bold", sans-serif;
    font-weight: 700;
    border-bottom: 0px;
}

.dropDownMenuDetailsTechnology {
    //box-sizing: border-box;
    word-wrap: break-word;
    //margin-left: 15px;
    margin-right: 15px;
    //width: calc(100% - 30px);
    margin-top: 20px;
    //color: #666;
    font-family: "Helvetica", sans-serif;
}

.dropDownMenuDetailsTechnology li {
    display: flex;
    align-items: center;
    list-style: none;
}

.dropDownMenuDetailsTechnologyNumbers {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #8e8181;
    margin-right: 58px;
}



/* Image Buttons */
.product-Image-carousel-container {
    position: relative;
    box-sizing: border-box;
    max-height: 400px;
    min-width: 400px;
    max-width: 400px;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 0 0 0 1px #7a7a7a;
    overflow: hidden;
}

.productImageCarousel {
   box-sizing: border-box;
   width: 400px;
   height: 400px;
}

.productImageCarousel img {
    border-radius: 15px;
    //width: auto;
    //height: auto;
    height: 100%;
    width: 100%;
    object-fit: contain;
}


.image-button-background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40%;
    height: 5rem;
    width: 30px;
    background-color: #889ab174;
    opacity: 1;
    cursor: pointer;
}

.image-carousel-left {
    //position: relative;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.image-carousel-right {
    //position: relative;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 370px;
}


.image-carousel-icon {
    border: 1px solid transparent;
    opacity: 1;
    transition: all 0.5s;
}


/*SHOWS THE ARROWS WHEN HOVERING OVER THE GENERAL CONTAINER*/
.product-Image-carousel-container:hover .image-button-background {
    opacity: 1;
}

.image-button-background:hover {
    background-color: #889ab18c;
}

.image-button-background:hover  .image-carousel-icon {
    opacity: 1;
    color: #fe8802;
}

.product-Image-carousel-container:hover .image-navigation-buttons.image-carousel-left {
    left: 14px;
}


.productImageCarousel-navigation {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 10px;
    height: 30px;
}

.image-indicator-location {
    //background-color: #dd00ff;
    background-color: rgb(255, 255, 255);
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 100%;
    border: 1px solid black;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.35rem;
    cursor: pointer;
}

.about-indicator-inactive {
    background-color: #f1f1f1;
    height: 0.5rem;
    width: 0.5rem;
}


.divider-technology-menu {
    color: #8e8181;
    width: 23px;
    height: 15px;
    border-right: 1px solid #8e8181;
    margin: 10px 0;
}

@media screen and (max-width: 1157px) {
    .productInformationInner {
        flex-direction: column;
    }
    .dynamicPageProfileInfo {
        padding-left: 20px;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 775px) {
    .image-description-container {
        flex-direction: column;
        align-items: center;
    }
    
    .product-Image-carousel-container {
        max-width: 300px;
        min-width: 300px;
        max-height: 300px;
    }

    .productImageCarousel {
        width: 300px;
        height: 300px;
        //width: 38.5vh;
    }

    .image-carousel-right {
        left: 270px;
    }

    .dynamicPageProfileInfo {
        height: 120px;
        margin: 10px 0 10px 0;
    }

    .productDescriptionShort {
        padding-left: 10px;
    }

    .productDescriptionLong {
        padding-left: 10px;
    }

    .productDescriptionSection {
        margin-right: 40px;
    }

    .dropDownMenuTechnologies {
        width: 90%;
        margin: 0 0 0 5%;
    }
    
}