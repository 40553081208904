.main-container {
  width: 100%;
  max-width: 1440px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add padding as needed */
  background-color: #FFFFF0;
}

.blog-write {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    max-width: 1440px;
}

.blog-writeContainer {
    display: flex;
    flex-direction: column;
    flex: 9;
    max-width: 1000px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.file-selection-write-page {
    display: flex;
    margin-bottom: 20px;
    font-family: "Helvetica", sans-serif;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
}

.file-selection-write-page label {
    padding-right: 10px;
}

.dropdown-toggle-write-page {
    display: flex;
    align-items: center;
    background-color: antiquewhite;
    border: #006666 solid 1px;
    border-radius: 5px;
    padding: 5px 5px;
    font-size: 14px;
    font-weight: 100;
    width: 100px;
    position: relative;
}

.dropdown-toggle-write-page:hover {
    background-color: rgb(207, 193, 175);
    cursor: pointer;
}

.chevron-write-page-posts {
    position: absolute;
    right: 8px;
    font-size: 10px;
}

.dropdown-list-write-page {
    //color: #FFFFF0;;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style: none;
    background-color: aqua;
    width: 100px;
    position: absolute;
    top: 28px;
    left: 230px;
    border-radius: 5px;
    font-family: 'Helvetica', sans-serif;
    text-decoration: none;
    padding: 5px 5px;
    z-index: 6;
    cursor: pointer;

}

.write-post-option {
    display: flex;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    align-items: center;
    padding: 2px;
}

.write-post-option:hover {
    background-color: antiquewhite;
    cursor: pointer;
}

.blog-writeFormGroup {
    //margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}

/*Icon for opening image input field*/
.blog-open-image-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
}

.blog-writeIcon {
    width: 25px;
    height: 25px;
    font-size: 20px;
    border: 1px solid;
    border-radius: 50%;
    color: rgb(129, 125, 125);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.blog-writeInput {
    font-size: 30px;
    border: none;
    width: 100%;
    background-color: rgb(125, 192, 119);
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px 0px rgba(0, 255, 76, 0.7),
            1px 2px 4px 0px rgba(0,255,76,0.7),
            2px 4px 8px 0px rgba(0,255,76,0.7),
            2px 4px 16px 0px rgba(0,255,76,0.7);
}
  
.blog-writeInput::placeholder {
    color: rgb(29, 25, 25);
    font-weight: 400;
}

.blog-writeInput:focus {
    outline-style: none;
}

.blog-writeText {
    width: 100%;
    padding-top: 10px;
    min-height: 60vh;
    font-family: inherit;
    font-size: 15px;
    resize:none
}


.blog-writeSubmit {
    color: white;
    background-color: teal;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, transform 0.1s;
}

.blog-writeSubmit:hover {
    background-color: #006666;
}

.blog-writeSubmit:active {
    transform: scale(0.95); /* Scale down the button slightly when pressed */
  }

.blog-writeSubmitOuter{
    //margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //width: fit-content;
    width: 100%;
}

/* Image Header */
.blog-write-ImageInputOutside{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}


.fileInputBackground{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 810px;
}

.fileInputBackground-image-closed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grayV1);
    padding: 10px;
    border-radius: 15px;
    height: 500px;
    width: 500px;
    cursor: pointer;
}


.fileInputBackground-image-closed:hover {
    background-color: rgb(103, 104, 105);
}

.fileInputBackground-image-open{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--color-dark-grayV1);
    border-radius: 15px;
    height: 500px;
    width: 500px;
    overflow: hidden;
}

.blog-writeHeaderImage {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.blog-writeHeaderImage-Border {
    //background-color: rgba(0, 0, 0, 0.5); /* Set background color to transparent */
    border-radius: 15px;
    position: absolute;
    width: 400px;
    height: 400px;
    z-index: 3;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    display: flex;
}

/*.blog-writeHeaderImage img {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: 2;
    object-fit: cover;
}*/

.blog-image-styling {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: move;
}

.blog-image-scale-location {
    position: absolute;
    bottom: 10px;
    z-index: 3;
}


/* Style For the + Sybol Add More Images */
.fileInput-AddMore-Symbol {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 5;
}

.blog-addMoreImagesIcon {
    font-size: 25px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(68, 76, 83, 0.9);
    background-color: rgb(155, 163, 163);
    border-radius: 50%;
    cursor: pointer;
}

.blog-addMoreImagesIcon:hover {
    color: rgb(57, 137, 216);
    background-color: rgb(222, 233, 233);
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
}

/* Stle for the Remove Symbol */
.fileInput-Remove-Symbol {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 5;
}

.blog-addRemoveImagesIcon {
    font-size: 20px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(187, 49, 49, 0.9);
    border-radius: 50%;
    cursor: pointer;
}

.blog-addRemoveImagesIcon:hover {
    color: rgb(231, 27, 27);
}

/* Style inside the image field*/
.fileInput-files-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
}

.blog-OpenFileIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    height: 45px;
    width: 45px;
    color: aliceblue;
    background-color: var(--color-light-grayV1);
    border-radius: 50%;
    cursor: pointer;
}


.fileInputBackground-top-text {
    display: flex;
    text-align: center;
    justify-self: center;
    font-size: 25px;
    color: aliceblue;
}

.fileInputBackground-bot-text {
    display: flex;
    text-align: center;
    justify-self: center;
    font-size: 15px;
    color: aliceblue;
}

/*Bottom General Information*/
.write-page-general-information {
    margin-bottom: 30px;
}

.blog-writeInput-Ingredients {
    display: flex;
    margin: 20px 0 20px 0;
    flex-direction: column;
    width: 100%;
}

.input-fields-write-page {
   // width: auto;
   //flex-grow: 1;
    margin-right: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
    //min-width: 200px;
    //resize: horizontal; 
    //height: 100px;
}

.write-page-input-TechCont {
    margin-top: 30px;
}

@media screen and (max-width: 650px ) {

    .blog-write-general-outer {
        width: 300px;
    }

    .input-fields-write-page {
        margin: 10px 0 0 0;
    }

    .blog-writeContainer {
        max-width: 400px;
    }

    .fileInputBackground-image-closed {
        width: 300px;
        height: 300px;
    }

    .fileInputBackground {
        height: 670px;
    }

    .blog-writeInput-Ingredients {
        justify-content: center;
        align-items: center;
    }

    .write-page-general-information {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .fileInputBackground-image-open {
        width: 350px;
        height: 350px;
    }

    .blog-writeHeaderImage-Border {
        width: 250px;
        height: 250px;
    }
    
}