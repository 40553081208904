:root {
    --color-red-light: rgb(231, 77, 60);
    /* #E74D3C */
    --color-red-dark: rgb(192, 57, 43);
    /* #C0392B */
    --color-orange-light: rgb(230, 125, 34);
    /* #E67D22 */
    --color-orange-dark: rgb(211, 84, 0);
    /* #D35400 */
    --color-yellow-light: rgb(241, 196, 15);
    /* #F1C40F */
    --color-yellow-dark: rgb(243, 156, 18);
    /* #F39C12 */
    --color-green-light: rgb(46, 204, 112);
    /* #2ECC70 */
    --color-green-dark: rgb(39, 174, 96);
    /* #27AE60 */
    --color-turquoise-light: rgb(26, 188, 156);
    /* #1ABC9C */
    --color-turquoise-dark: rgb(22, 160, 132);
    /* #16A084 */
    --color-blue-light: rgb(52, 152, 219);
    /* #3498DB */
    --color-blue-dark: rgb(41, 128, 185);
    /* #2980B9 */
    --color-purple-light: rgb(156, 89, 182);
    /* #9C59B6 */
    --color-purple-dark: rgb(142, 68, 173);
    /* #8E44AD */
    --color-white-light: rgb(236, 240, 241);
    /* #ECF0F1 */
    --color-white-dark: rgb(189, 195, 199);
    /* #BDC3C7 */
    --color-gray-light: rgb(149, 165, 166);
    /* #95A5A6 */
    --color-gray-dark: rgb(127, 140, 141);
    /* #7D8C8D */
    --color-black-light: rgb(52, 73, 94);
    /* #34495E */
    --color-black-dark: rgb(44, 62, 80);
    /* #2c3e50 */
    --color-black-dark2: rgb(27, 23, 23);
    --color-black-light2: rgb(34, 32, 32);;
    /* 2d3032 */
    --color-black-dark-light: rgb(45,48,50);
}

/* Apply styles to the outer container */
.Main-nav-bar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 9%;
    z-index: 5;
    background-color: var(--color-white-dark);
    box-shadow: 0 0 10px var(--color-black-dark2);
}
/*used when scrolling down*/

.Main-nav-bar2{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 9%;
    z-index: 5;
    background-color: var(--color-white-dark);
    box-shadow: 0 0 10px var(--color-black-light2);
}

/* Apply styles to the outer container */
.navigation-bar-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 2000px;
}

.navigation-bar-top>ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    padding: 0; /* Remove default padding */
}

.navigation-bar-top>ul li {
    text-align: center;
    display: inline-block;
    margin-right: 1em;
}

.navigation-bar-top>ul li #ntb-bar-links {
    text-transform: capitalize;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    padding: 14px;
    line-height: 50px;
    border-radius: 15px;
    transition: 0.3s;
}

.navigation-bar-top>ul li:hover #ntb-bar-links {
    color: rgba(255, 255, 255, 1);
    background: rgba(204, 204, 204, 0.5);
    -webkit-box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    border-radius: 15px;
    cursor: pointer;
}




/* Image position */
.Main-nav-img {
    display: flex;
    justify-content: center;
}


.Main-nav-img img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    padding: 8px 0;
    cursor: pointer;
}


/*Sign In CSS*/
.navigation-bar-right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


/* Image position */
.search-img-nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-img-nav-bar>button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}



.navigation-bar-right-side-icons{
    display: flex;
}

.search-img-nav-bar img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    cursor: pointer;
    
}

.login-img-nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
}

.login-img-nav-bar img{
    height: 35px;
    width: 35px;
    object-fit: contain;
    cursor: pointer;
}




