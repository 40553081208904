.blog-posts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    max-width: 1440px; /* Assuming this is the width of your container */
    padding-left: 112px;
    padding-right: 112px;
    width: 100%;
}

.blog-posts {
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //flex-direction: column;
    width: 100%;
}

