.Shopping-Cart-Container {
    position: relative;
}

.Shopping-Cart-Top-Bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    font-family: Helvetica, Arial, sans-serif;
    background-color: aliceblue;
    min-width: 450px;
    min-height: 160px;
    top: 40px;
    right: -80px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    list-style: none;
    z-index: 1;

    .Shopping-Cart-Title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        border: #5a3636;
        padding: 0 10px 0 10px;
        border-radius: 10px 10px 0 0;
        background-color: aliceblue;

        .Shopping-Cart-TitleStyle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 16px;
            font-weight: 600;
        }

        .Shopping-Cart-ClearButton {
            background: none;
            color: rgba(255, 63, 63);
            text-decoration: underline;
            text-underline-offset: 5px;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            height: 100%;
            font-size: 14px;
        }

        .Shopping-Cart-ClearButton:hover {
            color: rgb(255, 107, 107);
        }

    }

    .Shop-Cart-Items-Section {
        display: flex;
        min-height: 70px;
        height: 100%;
    
        .Shop-Cart-Items-List {
            list-style: none;
            padding: 10px 10px 10px 10px;
            width: 100%;

            .Shop-Cart-Items-Inner {
                //padding: 10px 10px 10px 10px;
                display: flex;
                width: 100%;
                padding: 0 0 10px 0;


                .Shop-Cart-Product {
                    display: flex;
                    width: 100%;

                    .Shop-Cart-Image {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 100px;
                        margin-right: 5px;

                        .Shop-Cart-Image-Inner {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .Shop-Cart-Description-Section {
                        display: flex;
                        width: 320px;
                        //justify-content: flex-end;
                        justify-content: space-between;
                        //align-items: center;

                        .Shop-Cart-Product-Name {
                            font-size: 14px;
                            line-height: 1.14286;
                            text-wrap: wrap;
                            letter-spacing: 1px;
                            width: 150px;
                            margin-left: 10px;
                            //margin-right: 15px;
                        }
    
                        .Shop-Cart-Product-Quantity {
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            .Shop-Cart-Product-Quantity-Remove {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: none;
                                background-color: #a3a3a3;
                                border: none;
                                //border: 4px solid red;
                                border-radius: 50%;
                                height: 20px;
                                width: 20px;
                                margin: 0 9px 0 0;
                            }
    
                            .Shop-Cart-Product-Quantity-Add {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: none;
                                background-color: #a3a3a3;
                                border: none;
                                border-radius: 50%;
                                height: 20px;
                                width: 20px;
                                margin: 0 0 0 9px;
                            }

                            .Shop-Cart-Product-Quantity-Remove:hover {
                                cursor: pointer;
                                background-color: #f3a6a6;
                            }

                            .Shop-Cart-Product-Quantity-Add:hover {
                                cursor: pointer;
                                background-color: #96ec64;
                            }

                        }

                        .Shop-Cart-Product-Quantity-ItemSummary {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            font-family: "Roboto", sans-serif;
                            //justify-content: flex-end;
                            width: 70px;

                            .Shop-Cart-Product-Quantity-ItemPrice {
                                font-size: 17px;
                                font-weight: 600;
                            }

                            .Shop-Cart-Product-Quantity-ItemSave {
                                margin-top: 5px;
                                text-align: end;
                                font-size: 10px;
                                color: rgb(56, 96, 141);
                            }

                            .Shop-Cart-Product-Quantity-ItemSave:hover {
                                color: rgb(86, 124, 167);
                                cursor: pointer;
                            }

                            .Shop-Cart-Product-Quantity-ItemRemove{
                                margin-top: 5px;
                                text-align: end;
                                font-size: 10px;
                                color: rgb(255, 63, 63);
                            }

                            .Shop-Cart-Product-Quantity-ItemRemove:hover {
                                cursor: pointer;
                                color: rgb(255, 107, 107);
                            }

                        }
                    }
                }

            }
        }
    }

    .Shop-Cart-Items-Total-Section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 10px 0 10px;
        width: 100%;
        height: 50px;
        font-family: "Roboto", sans-serif;

        .Shop-Cart-Items-Total-SectionStyle {
            display: flex;
            //flex-direction: column;
            justify-content: flex-end;
            border-top: 2px #a3a3a3 solid;
            width: 320px;
            height: 100%;

            .Shop-Cart-Items-Total-Subtotal {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 10px;
                padding-top: 5px;

                .Shop-Cart-Items-Total-Text {
                    font-size: 16px;
                    font-weight: 600;
                }

                .Shop-Cart-Items-Total-CountItem {
                    font-size: 12px;
                    color: #6e6a6a;
                }

            }

            .Shop-Cart-Items-Total-Amount {
                display: flex;
                align-items: center;
                padding-top: 3px;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .Shop-Cart-Buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end; 
        padding: 10px;
        width: 100%;
        font-family: "Roboto", sans-serif;

        .Shop-Cart-Checkout {
            padding: 10px 30px 10px 30px;
            font-size: 14px;
            letter-spacing: 1px;
            border-radius: 10px;
            background: none;
            border: none;
            background-color: #71c1cc;
        }

        .Shop-Cart-Checkout:hover {
            cursor: pointer;
            background-color: #8ee1ec;
        }
    }
}
