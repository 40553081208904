.Blog-Header-Main-Container {
    display: flex;
    position: relative;
    //flex-direction: column;
    width: 100%;
    height: 500px;
    //background: rgb(204,102,51);
    background: linear-gradient(0deg, rgba(204,102,51,1) 50%, #722833 50%); 
}

.Blog-Header-Main-Container-Mobile {
    display: none;
}

.Blog-Header-Main-Container-Image {
    display: flex;
    width: 100%;
    height: 500px;
    z-index: 1;
    position: absolute;
}

.Blog-header-background-IMG {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
}


.Blog-Header-Main-Top-BackGround {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center horizontally */
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 20%;
    position: relative;
    z-index: 2;
    //margin-right: 100px;
    //padding-right: 120px;
    //min-width: 750px
    //background-color: rgba(102,51,51,1);
}


.Blog-Header-Main-Bottom-BackGround {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    width: 100%;
    height: 50%;
    background-color: rgba(204,102,51,1);
}


.Blog-Header-Main-Top-Container {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 2000px;
}

.blog-header-floating-text-style {
    font-family: 'Verdana', sans-serif;
    color: #fffff0;
    font-size: 13px;
    font-weight: 600;
    //letter-spacing: 1px;
}

.floating-text-pos-1 {
    position: absolute;
    top: 95px;
    left: 300px;
}
    
.floating-text-pos-2 {
    position: absolute;
    top: 150px;
    left: 500px;
}

.floating-text-pos-3 {
    position: absolute;
    top: 0px;
    left: 450px;

}
.floating-text-pos-4 {
    position: absolute;
    top: 110px;
    left: 330px;
}

.Blog-Header-Main-Top-Text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    //margin-left: 10%; /* Adjust as needed */
    padding-top: 90px;
    height: 100%;
    max-width: 500px;
    //margin: 150px 0 0 100px;
    //background-color: aqua;
}

.Blog-Header-Main-Inner-Top-Text {
    position: relative;
    color: rgba(212, 196, 197,1);
    //font-family: "Dancing Script", cursive;
    font-family: segoescript;
    font-size: 48px;
    line-height: 1;
    padding-left: 10px;
}

.Blog-Header-Main-Inner-Bot-Text {
    position: relative;
    color: var(--color-main-color-ivory);
    font-family: 'Verdana', serif;
    font-weight: 600;
    font-size: 60px;
    line-height: 1;
    word-wrap: break-word;
}


.Blog-Header-Main-Bottom-Container {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 2000px;
}

.Blog-Header-Main-Bottom-Text {
    display: flex;
    flex-direction: column;
    //margin-left: 10%; /* Adjust as needed */
    padding-top: 10px;
    height: 200px; 
    max-width: 500px;
}

.Blog-Header-Main-Inner-Bottom-Top-Text {
    position: relative;
    color: #663333;
    max-width: 500px;
    font-family: 'Verdana', serif;;
    font-size: 24px;
    word-wrap: break-word;
}

.Blog-Header-Main-Inner-Bottom-Bot-Text {
    position: relative;
}


.Blog-Header-Main-Inner-Bottom-Bot-Text-Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    z-index: 1;
    //height: 125px;
    //background-color: #663333;
}

.Blog-Header-Main-Inner-Bottom-Bot-Text-Image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Blog-Header-Main-Inner-Bottom-Bot-Text > span{
    z-index: 2;
    position: absolute;
    top: 30px;
    left: 33px;
    font-family: 'Verdana', serif;
    text-transform: uppercase;
    font-size: 24px;
    //background-color: aliceblue;
    color: rgba(212, 196, 197,1);
}


/*.Blog-Header-Main-Images-Containers-Right {
    position: absolute;
    right: 1000px;
    width: 400px;
    height: 300px;
    background-color: aqua;
}*/


.Blog-Header-Main-Right-Images {
    display: flex;
    //justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
    //background-color: aliceblue;
}

.Blog-Header-Main-Right-Images-Container {
    display: flex;
    position: relative;
    z-index: 7;
    //margin-right: 300px;
    width: 500px;
    height: 500px;
}

.Blog-Header-Main-Right-Images-Container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Blog-Header-Main-Right-Images-New-Container {
    display: flex;
    position: absolute;
    top: 105px;
    left: 185px;
    z-index: 8;
    //margin-right: 300px;
    width: 65px;
    height: 65px;
}

.Blog-Header-Main-Right-Images-New-Container >div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #913241;
    border-radius: 50%;
}

.Blog-Header-Main-Right-Images-New-Container >div>span {
    font-family: 'Verdana', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--color-main-color-ivory);
}

.blog-header-main-whitedot-outside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 11px;
    //background-color: #722833;
    border: 2px solid #fffff0;
    border-radius: 50%;
    //background: radial-gradient(circle, rgba(255,255,240,1) 25%, rgba(170,85,51,1) 25%, rgba(170,85,51,1) 50%, rgba(170,85,51,1) 75%, rgba(255,255,240,1) 75%);
}


.blog-header-main-whitedot-inside {
    width: 3px;
    height: 3px;
    //margin: 1px 1px 1px 1px;
    background-color: #fffff0;
    border-radius: 50%;
}


.white-dot-position-1 {
    position: absolute;
    top: -8px;
    left: 207px;
}

.white-dot-position-2 {
    position: absolute;
    top: 9px;
    left: 398px;
}

.white-dot-position-3 {
    position: absolute;
    top: -5px;
    left: 185px;
}

.white-dot-position-4 {
    position: absolute;
    z-index: 3;
    top: 56px;
    left: 267px;
}


@media screen and (max-width: 1660px) {
    .Blog-Header-Main-Top-BackGround {
        padding-left: 10%;
        //background-color: rgba(102,51,51,1);
    }
}

@media screen and (max-width: 1440px) {
    .Blog-Header-Main-Container-Image {
        height: 300px;
    }
    
    .Blog-Header-Main-Right-Images-Container {
        width: 300px;
        height: 300px;
        margin-left: 200px;
    }

    .Blog-Header-Main-Right-Images-New-Container {
        top: 70px;
        left: 325px;
        width: 30px;
        height: 30px;
    }

    .Blog-Header-Main-Right-Images-New-Container >div>span {
        font-size: 10px;
    }

    
    // Left Header Main Text
    .Blog-Header-Main-Inner-Top-Text {
        font-size: 24px;
        
    }

    .Blog-Header-Main-Bottom-Text {
        height: 150px;
    }

    .Blog-Header-Main-Inner-Bot-Text {
        font-size: 30px;
    }

    .Blog-Header-Main-Top-Text {
        padding-top: 60px;
    }

    .Blog-Header-Main-Container {
        height: 300px;
    }

    .Blog-header-background-IMG {
        height: 300px;
    }

    .Blog-Header-Main-Inner-Bottom-Top-Text {
        max-width: 255px;
        font-size: 14px;
    }

    .Blog-Header-Main-Inner-Bottom-Bot-Text-Image {
        width: 175px;
    }

    .Blog-Header-Main-Inner-Bottom-Bot-Text > span {
        font-size: 14px;
        top: 17px;
        left: 20px;
    }

    .white-dot-position-1 {
        top: -8px;
        left: 110px;
    }
    
    .white-dot-position-2 {
        top: 2px;
        left: 200px;
    }
    
    .white-dot-position-3 {
        top: -7px;
        left: 107px;
    }
    
    .white-dot-position-4 {
        top: 32px;
        left: 150px;
    }

    .blog-header-main-whitedot-outside {
        height: 8px;
        width: 8px;
        border: 1px solid #fffff0;
    }

    .blog-header-main-whitedot-inside{
        height: 2px;
        width: 2px;
    }

    .floating-text-pos-1 {
        top: 60px;
        left: 150px;
    }
        
    .floating-text-pos-2 {
        top: 90px;
        left: 280px;
    }
    
    .floating-text-pos-3 {
        top: 0px;
        left: 270px;
    
    }
    .floating-text-pos-4 {
        top: 60px;
        left: 200px;
    }

    .blog-header-floating-text-style {
        font-size: 10px;
    }

}

@media screen and (max-width: 1024px) {
    .Blog-Header-Main-Top-BackGround {
        padding-left: 5%;
    }
}

@media screen and (max-width: 768px) {
    .Blog-Header-Main-Top-Container {
        margin-left: 2%; /* Adjust as needed */
    }

    .Blog-Header-Main-Top-BackGround {
        padding-left: 5%;
        //background-color: rgba(102,51,51,1);
    }
}

@media screen and (max-width: 737px) { 
    .Blog-Header-Main-Container {
        display: none;
    }

    .Blog-Header-Main-Container-Mobile {
        display: flex;
        position: relative;
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        //border-top: 20px solid #663333;
        //border-bottom: 20px solid #663333;
    }

}

