.singUpBlog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: aqua;
    height: 90vh;
}

.signUpForm {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.sing-up-image {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sing-up-image > img {
    width: 200px;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
}

.signUPIconPP {
    width: 25px;
    height: 25px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    margin-left: 10px;
    color: white;
    background-color: rgb(255, 3, 3);
    cursor: pointer;
}

  
.signUPIconPP:focus {
    outline-style: none;
}