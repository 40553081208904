.technologies-input-outer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-width: 300px;
    height: 370px;
    margin-bottom: 10px;
}

.technologies-button-remove {
    height: 30px;
}

.technologies-input-number {
    margin-bottom: 10px;
}

.text-area-technologies-option {
    width: 100%;
    height: 300px;
    //min-width: 500px;
    //min-height: 200px;
    font-family: inherit;
    font-size: 15px;
    resize:none
}

.technologies-button-remove {
    position: absolute;
    right: 10px;
    bottom: 0px;
}

.technologies-button-add {
    width: 100px;
}





@media screen and (max-width: 620px) {
    .text-area-technologies-option {
        max-width: 300px;
        min-width: 300px;
    }
    
}