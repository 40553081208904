.blog-pages-posts-container {
    display: flex;
    width: 100%;
    min-height: 81vh;
    flex-direction: column;
}

.blog-singlePost {
    display: flex;
    //justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.blog-list-style-1 {
    display: flex;
    //flex-direction: column;
    padding: 20px;
    padding-right: 20px;
    width: 100%;
    //min-width: 700px;
    box-sizing: border-box;
}

.blog-list-style-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-list-style-information-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(70% - 30px);
    min-height: 218px;
}

.blog-pages-posts-header-image-container {
    max-width: 300px;
    max-height: 300px;
    //padding-right: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.blog-singlePostImg {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.blog-singlePostTitle {
    //text-align: center;
    margin: 20px 0;
    font-family: 'Verdana', serif;
    font-size: 28px;
}

.blog-singlePostEdit {
    //float: right;
    position: absolute;
    right: 0;
    font-size: 16px;
}

.blog-singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.blog-singlePostIcon {
    color: tomato;
}

.blog-singlePostInfo {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #888;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.blog-singlePostAuthor {
    margin-left: 5px;
    max-width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blog-singlePostDesc {
    font-family: 'Verdana', sans-serif;
    color: #666;
    font-size: 14px;
    line-height: 25px;
    overflow-wrap: break-word;
    hyphens: manual;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
}

.blog-singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
}

.dynamicPostRedirectStyle-text {
    text-decoration: none;
}

.dynamicPostRedirectStyle-text:link {
    color: black;
}

.dynamicPostRedirectStyle-text:visited {
    color: black;
}

.dynamicPostRedirectStyle-text:hover {
    color: var(--color-top-bar-hover);
}

.dynamicPostRedirectStyle-desc {
    text-decoration: none;
}

.dynamicPostRedirectStyle-desc:link {
    color: black;
}

.dynamicPostRedirectStyle {
    width: calc(30% - 30px);
    margin-right: 10px;
    //border-radius: 50px;
    //overflow: hidden;
    text-decoration: none;
}

.dynamicPostRedirectStyle:link {
    color: black;
}

.dynamicPostRedirectStyle:visited {
    color: black;
}

.editPost-Redirect {
    margin: 0 5px 0 0;
    color: teal;
}
.editPost-Redirect:link {
    color: teal;
}

.editPost-Redirect:visited {
    color: teal;
}


//Bottom Bar Container
.blog-list-style-time-container {
    display: flex;
    align-items: center;
    margin-top: 22px;
    font-family: 'Verdana', sans-serif;
    line-height: 14px;
    color: #888;
}

.title-tag-hash {
    margin-right: 25px;
}

.blog-list-style-time-container i{
    margin-right: 7px;
}

.blog-list-style-time-posted {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 20px;
}

.blog-pages-posts-title-header {
    display: flex;
    position: relative;
    text-transform: uppercase;
    color: var(--color-main-background);
    font-family: 'Verdana', sans-serif;
}

.blog-pages-posts-divider {
    border-bottom: 1px solid rgba(0,0,0,.12);
    margin-top: 20px;
}


@media screen and (max-width: 768px) {
    .dynamicPostRedirectStyle {
        display: flex;
        width: 100%;
        padding: 20px 0 0 0;
        align-items: center;
        justify-content: center;
    }

    .blog-singlePostTitle {
        font-size: 24px;
    }

    .blog-singlePostDesc {

    }

}