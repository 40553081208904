.drop-down-menu-ingredient-container {
    //width: 36%;
    width: 50%;
}

.dropDownMenuDetailsIngredients {
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    margin-top: 20px;
    font-family: "Helvetica", sans-serif;
}

.dropDownMenuDetailsIngredients li {
    display: flex;
    align-items: center;
    list-style: none;
}

.dropDownMenuDetailsTechnology-container {
    //width: 71%;
    width: 50%;
    margin-left: 15px;
    //background-color: #7a7a7a;
}



.number-menu-details-ingredients {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #8e8181;
    margin-right: 58px;
}

.details-menu-details-ingredients {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.details-menu-details-ingredients h2 {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: .0025em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
    width: 40%;
}

.details-menu-details-percentage-w70 {
    width: 30%;
    text-align: right; 
} 

.divider-details-menu {
    color: #8e8181;
    width: 23px;
    height: 15px;
    border-right: 1px solid #8e8181;
    margin: 10px 0;
}

.header-phase {
    width: 40px;
    text-align: center;
    margin: 40px 0 0 15px;
    justify-content: center;
    font-family: "Helvetica-Bold", sans-serif;
    font-weight: 700;
    font-size: 25px;
}

@media screen and (max-width: 775px) {

    .drop-down-menu-ingredient-container {
        width: 100%;
    }

    .dropDownMenuDetailsTechnology-container {
        width: 90%;
    }

    .number-menu-details-ingredients {
        margin-right: 20px;
    }

    .dropDownMenuDetailsTechnologyNumbers {
        margin-right: 20px;
    }

    .details-menu-details-percentage-w70 {
        width: 20%;
    }

    .details-menu-details-ingredients h2 {
        width: 60%;
    }

    .dropDownMenuDetailsIngredients {
        width: calc(100% - 40px);
    }

    .details-menu-details-ingredients {
        font-size: 16px;
    }



}