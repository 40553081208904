.fileInputBackground-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
}

.fileInputBackgroundHeader-image-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grayV1);
    border-radius: 15px;
    height: 300px;
    width: 300px;
}

.fileInputBackgroundHeader-image-closed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grayV1);
    padding: 10px;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    cursor: pointer;
}

.fileInputBackgroundHeader-image-closed:hover {
    background-color: rgb(103, 104, 105);
}

.blog-writeDisplay-Image {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

.blog-writeDisplay-Image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

/* The X Symbol */
.fileInput-close-symbol {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;
}

.blog-closeImageIcon {
    font-size: 25px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: rgb(68, 76, 83);
    background-color: rgb(155, 163, 163);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.blog-closeImageIcon:hover {
    color: rgb(96, 103, 110);
    background-color: rgb(222, 233, 233);
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
}
