.dynamic-page-container {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DynaminPosts-Container {
    width: 100%;
    max-width: 1440px; /* Adjust as needed */
    margin: 0 auto; /* Center the container */
}

.DynamicPost-Inner-Container {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    min-height: 1000px;
}