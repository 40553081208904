.Main-page-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    //width: 100%;
    //height: 100%;
    //margin-left: 25%;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
}


.redirect-blog-home {
    display: flex;
    position: relative;
    //border: 5px solid red;
    flex-wrap: wrap;
    //max-width: 1000px;
    //padding: 0 0 0 50px;
    padding-left: 50px;
    min-width: 300px;
    width: 360px;
    height: 300px;
    margin: 40px 40px 40px 40px;
    //background-color: aqua;
    //cursor: pointer;
}

.redirect-blog-home-link {
    width: 300px;
    height: 300px;
    background-color: rgb(139, 37, 37);
    cursor: pointer;
}

.redirect-blog-home-link:link {
    color: #FFFFF0;
}

.redirect-blog-home-link:visited {
    color: #FFFFF0;
}

.redirect-blog-home-images {
    width: 300px;
    height: 300px;
}

.redirect-blog-home-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.redirect-title-postion {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 30px;
    width: 200px;
    height: 50px;
}


.redirect-title-home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: 'Verdana', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 5px;
    background-color: rgb(40, 117, 184);
    letter-spacing: 1.1px;
}

.redirect-title-home-body-color {
    background-color: #669999;
}

.redirect-title-home-face-color {
    background-color: #cc9966;
}

.redirect-title-home-scrubs-color {
    background-color: #663333;
}

.redirect-title-home-general-color {
    background-color: #4a5664;
}

/* Styles for screens between 700px and 1080px */
@media (min-width: 1650px) and (max-width: 2220px) {
    
    .Main-page-button-container {
        //margin-left: 15%;
        justify-content: flex-start; 
        //margin: 0 auto;
    }

    .blog-home-outer {
        margin-left: 10%;
    }
}

@media screen and (max-width: 800px) {
    .Main-page-button-container {
        margin-top: 20px;
    }

    .redirect-blog-home {
        width: 100px;
        height: 200px;
        margin: 0px 2px 30px 2px; 
    }

    .redirect-blog-home-link {
        width: 200px;
        height: 200px;
        margin: 2px;
    }

    .redirect-blog-home-images {
        width: 200px;
        height: 200px;
    }

    .redirect-title-postion {
        width: 100px;
        height: 30px;
    }
    
    .redirect-title-home {
        font-size: 10px;
    }
}