.loginBlog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 95vh;
    background: linear-gradient(#141e30, #243b55);
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    width: 100%;
}

.login-box {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 15px;
    padding: 40px;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
}

.login-box h2 {
    margin: 0 0 40px;
    padding: 0;
    color: #fff;
    text-align: center;
}

.loginForm {
    display: flex;
    flex-direction: column;
}

.loginFrom-box {
    position: relative;
    margin-bottom: 30px; 
}

.loginFrom-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.loginFrom-box .filled-login {
    top: -30px;
    font-size: 12px;
    color: #03e9f4;
}


.loginFrom-box:focus-within label {
    top: -20px;
    font-size: 12px;
    color: #03e9f4;
}


.loginFrom-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}


.loginFrom-box input:focus {
    border-bottom: 1px solid #03e9f4; 
}


.loginForm a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    cursor: pointer;
}

.loginForm a:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0px #03e9f4,
                0 0 5px #03e9f4,
                0 0 10px #03e9f4,
                0 0 15px #03e9f4;
}

.loginForm a span {
    position: absolute;
    display: block;
}

.loginForm a span:nth-child(1) {
    top: 0;
    left: -100%;
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: btn-anim1 2s linear infinite;
}
@keyframes btn-anim1{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}
.loginForm a span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#03e9f4);
    animation: btn-anim2 2s linear infinite;
    animation-delay: .5s;
}
@keyframes btn-anim2{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}
.loginForm a span:nth-child(3){
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#03e9f4);
    animation: btn-anim3 2s linear infinite;
    animation-delay: 1s;
}
@keyframes btn-anim3{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}
.loginForm a span:nth-child(4){
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg,transparent,#03e9f4);
    animation: btn-anim4 2s linear infinite;
    animation-delay: 1.5s;
}
@keyframes btn-anim4{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100% ;
    }
}

@media screen and (max-width: 650px) {
    .login-box {
        width: 350px;
    }

    .loginForm a {
        margin-top: 5px;
    }
    
}