.blog-toggle-style {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blog-change-box-size {
    cursor: pointer;
}


/*
.blog-post{
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0px 25px 40px 25px;
}

.blog-post-style2{
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 20px;
    width: 100%;
    //min-width: 700px;
    box-sizing: border-box;
}
*/