.Ingredients-Inner-Fields-Edit {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 0 30px 0;
    width: 80%;
}

.Ingredients-Edit-Inner {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

}

.input-fields-list-edit-page {
    display: flex;
    margin-right: 5px;
    min-width: 200px;
}

.Edit-Add-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.Technologies-Edit-Inner {
    display: flex;
    margin: 10px 0 30px 0;
    flex-wrap: wrap;

}

.Technologies-Edit-Fields {
    padding-right: 10px;
}