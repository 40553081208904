.page-header-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    line-height: 200px;
    height: 200px;
    font-size: 72px;
    font-weight: 700;
    font-family: 'Verdana', sans-serif;
}