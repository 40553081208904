.settings-page-container {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.settings {
    width: 100%;
    max-width: 1440px; /* Adjust as needed */
    margin: 0 auto; /* Center the container */
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px 0;
}

.settingsTitleUpdate {
  font-size: 30px;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: var(--color-main-text);
}

.settings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 9px 0 rgba(245, 13, 13, 0.16);
  min-width: 600px;
  background-color: antiquewhite;
  border: 1px #555;
  margin: 10px 0 30px 0;
}

.settingsTitleDelete {
  color: red;
  font-size: 12px;
  cursor: pointer;
}

.settingsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  min-height: 65vh;
}

.profile-image-change {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
  min-height: 300px;
}


.settingsPP {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.settingsPP > img {
  height: 200px;
  width: 200px;
  border-radius: 20px;
  object-fit: cover;
}

.settingsPPIcon {
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  margin-left: 10px;
  color: white;
  background-color: rgb(255, 3, 3);
  cursor: pointer;
}

.settingsPPButton:focus {
  outline-style: none;
}

.change-userinfo {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  padding: 10px 10px 10px 10px;
}

.change-userinf-email {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.change-userinf-email label {
  padding: 0 10px 10px 0;
}

.change-userinf-email input {
  margin: 0 10px 10px 0;
}
.change-userinf-email button {
  margin: 0 10px 10px 0;
  width: 100px;
}

.center-submit-button-settings {
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-userinf-password {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.change-userinf-password label {
  padding: 0 10px 10px 0;
}

.change-userinf-password input {
  margin: 0 10px 10px 0;
}
.change-userinf-password button {
  margin: 0 10px 10px 0;
  width: 100px;
}

.change-userinf-username {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.change-userinf-username label {
  padding: 0 10px 10px 0;
}

.change-userinf-username input {
  margin: 0 10px 10px 0;
}
.change-userinf-username button {
  margin: 0 10px 10px 0;
  width: 100px;
}