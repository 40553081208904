.dropMenu{
    position: relative;
}

.SignInContainerMain {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #722833;
    border-radius: 16px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding: 5px;
    margin-left: 10px;
    min-width: 74px;
    cursor: pointer;
}

.SignInContainerMain:hover {
    background-color: rgba(83, 210, 238, 0.473);
    -webkit-box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16); 
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    border-radius: 16px;
}

.SignInContainerScrolled {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFF0;
    border-radius: 16px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding: 5px;
    margin-left: 10px;
    min-width: 74px;
    cursor: pointer;
}

.SignInContainerScrolled:hover {
    background-color: rgba(83, 210, 238, 0.473);
    -webkit-box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16); 
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    border-radius: 16px;
}


.SignInContainer-LoggedIn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}


.top-bar-right-login-icon-outside {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 31px;
    border: 1px solid #382e2e;
    //background-color: aqua;
    overflow: hidden;
}

.top-bar-right-login-icon {
    font-size: 20px;
    color: #382e2e;
}

.top-bar-right-login-img-present-outside {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    overflow: hidden;
    transition: width 0.3s ease, height 0.3s ease;
}

.top-bar-img-size-50 {
    width: 50px;
    height: 50px;
}

.top-bar-img-size-40 {
    width: 40px;
    height: 40px;
}

.top-bar-right-login-img-present {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.top-bar-right-login {
    color: #722833;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
}

.top-bar-right-login-scrl {
    color: #FFFFF0;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
}

.dropDownMenuStyle {
    display: none;
    position: absolute;
    background-color: #382e2e;
    min-width: 160px;
    top: 40px;
    right: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    list-style: none;
    z-index: 1;
}

.dropDownMenuStyleLoggedIN {
    display: none;
    position: absolute;
    background-color: #382e2e;
    min-width: 160px;
    top: 55px;
    right: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    list-style: none;
    z-index: 1;
}



.show {display:block;}

.dropList {
    color: #FFFFF0;;
    font-family: 'Helvetica', sans-serif;
    text-decoration: none;
    padding: 10px 10px;
    cursor: pointer;
}

.dropLink {
    text-decoration: none;
    color: #FFFFF0;
}

.dropLink:hover {
    text-shadow: 0 0 7px rgb(190 254 194), 0 0 122px rgb(173 236 175)
}

.dropLink:visited {
    color: #FFFFF0;;
}

.dropLink:visited:hover {
    text-shadow: 0 0 2px rgb(190 254 194), 0 0 122px rgb(173 236 175)
}


 /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
 .show {
    display:block;
}

@media screen and (max-width: 620px) {
    .top-bar-right-login {
        font-size: 12px;
    }

    .top-bar-right-login-scrl {
        font-size: 12px;
    }
}


@media screen and (max-width: 320px) {
    

    
}