.product-box-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: rgb(210,211,211);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3%;
}



.product-box-container-img {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: azure;
}


.product-box-container-img img {
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
    cursor: pointer;
}

.product-box-container-text {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.product-box-card-information {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
}

.product-box-card-review {
    display: flex;
    align-items: center; /* Align items vertically in the review section */
    margin-top: 5px; /* Add margin for spacing between review sections */
    padding-top: 2px;
    padding-bottom: 5px;
}

.product-box-icons {
    grid-row-start: 4;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 0 10px 2px;
}

.product-box-icons img {
    width: 20px;
    height: 20px;
    margin-right: 10px; /* Add margin for spacing between icon images */

}

.product-box-description-style{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.product-box-card-information-long-desc{
    margin-top: 2px;
}