/*Normal Color V1 Top Page */
.top-bar-blog {
    display: flex;
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    background-color: var(--color-main-background);
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
    margin: 0 auto;
    //transition: height 4s ease, background-color 4s ease;
}

.top-bar-blog-main {
    display: flex;
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    background-color: black;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
    margin: 0 auto;
}

/*Color V2 Scrolled Down On The Page */
.top-bar-blogScrolled {
    display: flex;
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    background-color: var(--color-main-background-secondary);
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
    margin: 0 auto;
    transition: height 0.2s ease, background-color 0.2s ease;
}

.top-bar-blog-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
}

/*Left Nagivation bar*/
.top-bar-left {
    flex: 3;
    display: flex;
    height: 100%;
    align-items: center;
    //justify-content: center;
}

.top-bar-left-logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    //justify-content: center;
    height: 100%;
}

.top-bar-left-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: nori, sans-serif;
    padding-top: 20px;
    font-size: 36px;
    color: #FFFFF0;
    //overflow: hidden;
}

/*Center Nagivation bar*/
.top-bar-center {
    //flex: 6;
    display: flex;
    //justify-content: flex-end;
    justify-content: center;
    align-items: center;
    //width: 55%;
    height: 100%;
}

.top-bar-list-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
}

.top-bar-list-navigation-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFF0;
    cursor: pointer;
}

.top-bar-list-navigation-items:hover {
    background-color: var(--color-top-bar-hover);
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
}

.link:link {
    color: #FFFFF0;
}

.link:visited {
    color: #FFFFF0;
}

//.link:hover {
//    box-shadow: 0 0 11px rgba(33,33,33,.2); 
//}


/*Right  Nagivation bar*/
.top-bar-right {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    //width: 45%;
    //justify-content: right;
    //margin-right: 10px;
}

.top-bar-right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-right: 10px;
}

.top-bar-right-search-img {
    font-size: 20px;
    color: #FFFFF0;
    cursor: pointer;
}

.top-bar-right-basket {
    font-size: 20px;
    color: #FFFFF0;
    margin-right: 10px;
    cursor: pointer;
}

// Testing alernative display for Home
.top-bar-left-logo-home-location {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: nori, sans-serif;
    padding-top: 20px;
    font-size: 36px;
    color: #722833;
} 

.top-bar-blog-home-location {
    display: flex;
    width: 100%;
    //height: 70px;
    position: sticky;
    top: 0;
    background-color: #FFFFF0;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 999;
    margin: 0 auto;
    height: 90px;
    transition: height 0.2s ease, background-color 0.2s ease;
}

.top-bar-right-search-img-home-location {
    color: #722833;
    font-size: 20px;
    cursor: pointer;
}

.top-bar-right-basket-home-location {
    color: #722833;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.top-bar-log-image-container {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.top-bar-right-icon {
    font-size: 12px;
    color: #722833;
}

.top-bar-right-icon-scrl {
    font-size: 12px;
    color: #FFFFF0;
}

.top-bar-right-login-img {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFF0;
    border-radius: 50%;
    width: 26px;
    height: 26px;
}

.top-bar-right-login-img-home {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #722833;
    border-radius: 50%;
    width: 26px;
    height: 26px;
}

@media screen and (min-width: 620px) {
    .top-bar-center {
        flex: 6;
    }
}


@media screen and (max-width: 620px) {
    .top-bar-blogScrolled {
        height: 50px;
    }

    .top-bar-list-navigation-items {
        margin-right: 10px;
        font-size: 10px;
        font-weight: 300;
        cursor: pointer;
    }

    .top-bar-right-login-img {
        border-radius: 50%;
        width: 15px;
        height: 15px;
    }

    .top-bar-right-login-img-home {
        border-radius: 50%;
        width: 15px;
        height: 15px;
    }

    .top-bar-right-icon {
        font-size: 7px;
    }

    .top-bar-right-icon-scrl {
        font-size: 7px;
    }

    .top-bar-left {
        display: none;
    }

    .top-bar-log-image-container {
        height: 68%;
        width: 68%;
    }

    .top-bar-center {
        //flex: 3;
        display: flex;
        padding-left: 10px;
        //justify-content: flex-end;
        //justify-content: center;
        //align-items: center;
        align-items: flex-start;
        //width: 55%;
        height: 100%;
    }

    .top-bar-blog-home-location{
        height: 70px;
    }

    .top-bar-right-search-img{
        font-size: 14px;
    }

    .top-bar-right-search-img-home-location {
        font-size: 14px;
    }

    .top-bar-right-basket-home-location {
        font-size: 14px;
    }

    .top-bar-right-basket {
        font-size: 14px;
    }
}










/*
.SignInContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    border-radius: 16px;
    box-sizing: border-box;
    font-family: 'Lora', serif;
    text-align: left;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.SignInContainer:hover {
    background-color: rgba(83, 210, 238, 0.473);
    -webkit-box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16); 
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    border-radius: 15px;
}



.top-bar-right-login-img {
    width: 25px;
    height: 25px;
}

.top-bar-right-login {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 300;
}*/


